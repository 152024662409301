import MainContent from '@/components/layout/main-content.vue'
import { _, isAuthorized, piloteAndAuditor } from '@/router/routing'
import { recurringTasksRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { incidentsRoutes } from '@/contexts/incidents/Incidents.routes'
import { gapsRoutes } from '@/contexts/gaps/Gaps.routes'
import { exemptionsRoutes } from '@/contexts/exemptions/Exemptions.routes'
import { projectsSecurityRoutes } from '@/contexts/projects-security/ProjectsSecurity.routes'
import { actionPlansRoutes } from '@/contexts/action-plans/ActionPlans.routes'
import { campaignsRoutes } from '@/contexts/campaigns/Campaigns.routes'
import type { Route, RouteConfig } from 'vue-router'

export const operationRoutes: RouteConfig = {
  path: '/ops',
  name: 'compliance',
  component: MainContent,
  meta: {
    title: _('operations'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    campaignsRoutes,
    actionPlansRoutes,
    recurringTasksRoutes,
    gapsRoutes,
    exemptionsRoutes,
    incidentsRoutes,
    projectsSecurityRoutes,
  ],
}
