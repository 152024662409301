export enum NdsIconType {
  CHEVRON_LEFT = 'ChevronLeft',
  CHEVRON_RIGHT = 'ChevronRight',
  CHEVRON_UP = 'ChevronUp',
  CHEVRON_DOWN = 'ChevronDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
  ARROW_DOWN_TO_LINE = 'ArrowDownToLine',
  PLUS = 'Plus',
  ELLIPSIS_VERTICAL = 'EllipsisVertical',
  CLOSE = 'X',
  PENCIL = 'Pencil',
  LINK = 'Link',
  GLOBE = 'Globe',
  CHECK = 'Check',
  SQUARE_CHECK = 'SquareCheck',
  CIRCLE_ALERT = 'CircleAlert',
  MESSAGE_SQUARE = 'MessageSquare',
  MESSAGE_SQUARE_PLUS = 'MessageSquarePlus',
  INFO = 'Info',
  SQUARE_ARROW_OUT_UP_RIGHT = 'SquareArrowOutUpRight',
  TRASH_2 = 'Trash2',
  ARROW_RIGHT_TO_LINE = 'ArrowRightToLine',
  ITERATION_CW = 'IterationCw',
  MESSAGE_CIRCLE_WARNING = 'MessageCircleWarning',
  TRENDING_UP = 'TrendingUp',
  LINE_CHART = 'LineChart',
  NOTEBOOK_PEN = 'NotebookPen',
  PLAY = 'Play',
  SKIP_FORWARD = 'SkipForward',
  PEN = 'Pen',
  CALENDAR_DAYS = 'CalendarDays',
  SEARCH = 'Search',
  CORNER_DOWN_RIGHT = 'CornerDownRight',
  FILE = 'File',
  NETWORK = 'Network',
  COPY = 'Copy',
  MOVE = 'Move',
  USER_CIRCLE = 'UserCircle',
  USERS = 'Users',
  TRAFFIC_CONE = 'TrafficCone',
  CLIPBOARD_CHECK = 'ClipboardCheck',
  GAUGE = 'Gauge',
}
